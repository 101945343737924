<template>
  <div class="home">
    <section id="first" class="pt-4 relative">
      <div class="logo ml-10">
        <router-link to="/"
          ><img class="logo-image" src="../assets/logo-tablita.svg"
        /></router-link>
      </div>
      <nav class="md:block hidden">
        <ul>
          <li>
            <router-link to="/ingreso-factura">Ingresa tu factura</router-link>
          </li>
          <li>
            <router-link to="/jugar">Juega y gana</router-link>
          </li>
          <li><router-link to="/">¿Cómo participo?</router-link></li>
          <li><router-link to="/">Premios</router-link></li>
        </ul>
      </nav>
      <div class="inner-container px-4 pt-4 pb-2 relative z-50">
        <div class="grid md:grid-cols-2 gap-12 md:px-20 px-4">
          <div class="logo">
            <img src="../assets/25_aniversario_tablita.png" />
          </div>
          <div>
            <!-- <img class="mx-auto" src="../assets/ingresa_participa.png"/> -->
            <h2 class="md:mt-14">Recupera tu contraseña</h2>
            <p>
              Si no recuerdas tu contraseña, ingresa tu correo y te enviaremos
              un correo de recuperación de contraseña
            </p>
            <input
              class="block w-10/12 my-4 mx-auto"
              type="text"
              name="usuario"
              v-model="email"
              placeholder="Email:"
            />

            <a @click="login" class="mr-6"
              ><img class="mx-auto" src="../assets/entrar-btn.png"
            /></a>
          </div>
        </div>
        <div class="mt-12 text-center text-white">
          <a href="legales.html" target="_blank">
            Aplican términos y condiciones.</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import firebase from "firebase";
export default {
  name: "InicioSesion",
  components: {
    // HelloWorld
  },
  data() {
    return {
      email: "",
      password: "",
      user_id: "",
    };
  },
  methods: {
    login() {
      firebase.auth();
      firebase
        .auth()
        .sendPasswordResetEmail(this.email)
        .then(() => {
          this.$router.push("/inicio-sesion");
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    forgotPassword() {},
  },
};
</script>
<style scoped>
#first {
  background: url("../assets/fondo-landing.jpg");
  overflow: hidden;
  min-height: 100vh;
}
input {
  border-radius: 15px;
  padding: 10px 15px;
}
input::placeholder {
  color: #430077;
}
h2 {
  font-size: 25px;
  color: white;
}
p {
  color: white;
}
.home {
}
.logo-image {
  max-width: 180px;
}
.inner-container {
  max-width: 1240px;
  margin: 0 auto;
}
.decorator1 {
  max-width: 200px;
}
.decorator2 {
  bottom: -90px;
}
.decorator3 {
  top: -90px;
  max-width: 200px;
}
.premios img {
  width: 15%;
}
nav {
  position: absolute;
  top: 53px;
  right: 100px;
  background: #dc6a06;
  border-radius: 2px;
  z-index: 100;
  padding: 5px 20px;
}
nav ul > li {
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
.forgot-password {
  color: white;
  font-style: italic;
  margin-top: -5px;
  margin-bottom: 15px;
  display: block;
}
</style>
