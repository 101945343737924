<template>
  <div class="home">
    <section id="first" class="pt-4 relative">
      <div class="logo ml-10">
        <router-link to="/"
          ><img class="logo-image" src="../assets/logo-tablita.svg"
        /></router-link>
      </div>
      <nav class="md:block hidden">
        <ul>
          <li>
            <router-link to="/ingreso-factura">Ingresa tu factura</router-link>
          </li>
          <li>
            <router-link to="/jugar">Juega y gana</router-link>
          </li>
          <li><router-link to="/">¿Cómo participo?</router-link></li>
          <li><router-link to="/">Premios</router-link></li>
        </ul>
      </nav>

      <div class="inner-container px-4 pt-4 pb-2 relative z-50">
        <div class="grid md:grid-cols-2 gap-12 md:px-20 px-4">
          <div class="logo">
            <img src="../assets/foto-factura.png" />
          </div>
          <div v-if="!registro_enviado" class="mt-10">
            <h2 class="mt-4 text-white text-3xl font-bold">
              Sube una foto de tu factura
            </h2>
            <h3 class="text-white">
              *Participan facturas por consumo mínimo de $10.
            </h3>
            <image-uploader
              class="image-uploader-container"
              :debug="1"
              outputFormat="file"
              :maxWidth="4048"
              :quality="0.7"
              :preview="true"
              :maxSize="4"
              :className="['fileinput', { 'fileinput--loaded': hasImage }]"
              :capture="false"
              accept="image/*"
              doNotResize="['gif', 'svg']"
              @input="setImage"
            >
              <label for="fileInput" slot="upload-label">
                <figure class="factura-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 94.1 95">
                    <title>factura</title>
                    <g id="Layer_1" data-name="Layer 1">
                      <path
                        d="M82,12.1,37.4,2.5,34.1,17.8H3V97.5H68.3V90.6l16.1,3.5L97.1,35.4Zm-.4,8.6L89,32.2l-9.5-2.1ZM63.3,92.5H7.9V22.7H45.1V41H63.2V92.5ZM50.1,36V26.3L59.8,36ZM80.6,88.2,68.3,85.5V76.1l4.9,1.1,1-4.8-6-1.3V61.7l7.9,1.7,1-4.8-8.9-1.9V47.3l10.9,2.4,1-4.8L68.2,42.3V37.6L48.6,18H39.1l2-9.5,36.4,7.8L73.7,34l17.7,3.8Z"
                        transform="translate(-3 -2.5)"
                      />
                      <rect x="14.5" y="75.9" width="36.2" height="5" />
                      <rect x="14.5" y="61.8" width="36.2" height="5" />
                      <path
                        d="M24.9,49.5A2.37,2.37,0,0,1,24,48l-3.5.5a5.85,5.85,0,0,0,2.1,3.7,6.38,6.38,0,0,0,2.6,1.3v3.4h3.5V53.6a7.46,7.46,0,0,0,3.1-1.5l.2-.2a6.85,6.85,0,0,0,2-5.2,6.46,6.46,0,0,0-2.6-4.8,8.28,8.28,0,0,0-3.9-1.6,4.46,4.46,0,0,1-2.3-1,2.62,2.62,0,0,1-.9-1.9A2.1,2.1,0,0,1,25,36a3.46,3.46,0,0,1,4.3.1,1.79,1.79,0,0,1,.5.7l3.6-.5a6.13,6.13,0,0,0-1.8-2.8A7.3,7.3,0,0,0,28.7,32V28.6H25.2V32a9.06,9.06,0,0,0-2.6,1.3l-.1.1-.1.1a5.8,5.8,0,0,0-1.9,3.9,5.91,5.91,0,0,0,1.9,4.5,7.85,7.85,0,0,0,4.1,2,6.4,6.4,0,0,1,2.6,1,3,3,0,0,1,1.2,2.2,3.11,3.11,0,0,1-1,2.5A3.76,3.76,0,0,1,24.9,49.5Z"
                        transform="translate(-3 -2.5)"
                      />
                    </g>
                  </svg>
                </figure>
                <span class="upload-caption">{{
                  hasImage
                    ? "Remplazar imagen"
                    : "Dale Click y sube la foto de tu factura"
                }}</span>
              </label>
            </image-uploader>
            <div class="error_form" v-if="error_imagen">
              <strong>*Por favor sube la foto de tu factura</strong>
            </div>
            <div class="text-white mb-4">
              Recuerda ingresar tu factura una sola vez
            </div>
            <a @click="sendData" class="mr-6"
              ><img class="mx-auto" src="../assets/guardar-btn.png"
            /></a>
          </div>
          <div v-else>
            <p class="text-white text-2xl md:mt-40 mt-4">
              Tu factura fue registrada con éxito
            </p>
          </div>
        </div>
        <div class="mt-12 text-center text-white">
          <a href="legales.html" target="_blank">
            *Promoción válida a nivel nacional para mayores de 12 años
            residentes en Ecuador, aplican términos y condiciones en la página
            web.</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Vue from "vue";
import axios from "axios";
import gql from "graphql-tag";

import ImageUploader from "vue-image-upload-resize";
Vue.use(ImageUploader);

export default {
  name: "InicioSesion",
  components: {
    // HelloWorld
  },
  data() {
    return {
      user_id: "",
      image: "",
      file: "",
      nombre: "",
      hasImage: false,
      registro_enviado: false,
      error_imagen: false,
      enviando: false,
      factura: "",
      url_factura: "/",
    };
  },
  methods: {
    async sendData() {
      console.log("button sending data");

      if (!this.enviando) {
        this.enviando = true;
        if (this.file != "") {
          this.error_imagen = false;
          await this.uploadImage(); //sube la imagen y luego sube el registro
        } else {
          this.error_imagen = true;
          this.enviando = false;
        }
      }
    },
    onFileChange() {
      this.file = this.$refs.file.files[0];
      console.log(this.file);
    },

    removeImage: function () {
      this.image = "";
    },

    setImage: function (output) {
      this.hasImage = true;
      this.image = output.file;
      console.log("Info", output);
      this.file = output;
    },
    registerUser() {
      console.log("enviando url de factura");
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createCompetitorBill(
              $competitor_external_id: String!
              $competitor_bill_url: String!
            ) {
              createCompetitorBill(
                competitor_external_id: $competitor_external_id
                competitor_bill_url: $competitor_bill_url
              ) {
                success
              }
            }
          `,
          variables: {
            competitor_external_id: this.user_id,
            competitor_bill_url: this.url_factura,
          },
        })
        .then((result) => {
          console.log(result);
          if (result.data.createCompetitorBill.success) {
            this.registro_enviado = true;
          } else {
            this.registro_enviado = false;
          }
        });
      this.enviando = false;
    },
    async uploadImage() {
      console.log("uploading image");
      var formdata = new FormData();
      formdata.append("file", this.file);
      formdata.append("nombre", this.nombre);

      // console.log (formdata);
      //    var actions = "uploadImage";
      const auth = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      axios
        .post(
          "https://fmwlab.com/tablita/api/uploadImage.php",
          formdata, // the data to post
          auth
        )
        .then((response) => {
          this.url_factura =
            "http://fmwlab.com/tablita/api/" + response.data.image;
          this.registerUser(); //registrar el usuario luego de tener la imagen
        });
    },
  },
  mounted() {
    // console.log(this.$store.state.user_id)
    try {
      this.user_id = this.$store.state.user_id;
      var prov_nombre = this.$store.state.email;
      this.nombre = prov_nombre.split("@").shift();
    } catch (err) {
      console.log(err);
    }
  },
};
</script>
<style scoped>
#first {
  background: url("../assets/fondo-landing.jpg");
  overflow: hidden;
  min-height: 100vh;
}
input {
  border-radius: 15px;
  padding: 10px 15px;
}
input::placeholder {
  color: #430077;
}
.home {
}
.logo-image {
  max-width: 180px;
}
.inner-container {
  max-width: 1240px;
  margin: 0 auto;
}
.decorator1 {
  max-width: 200px;
}
.decorator2 {
  bottom: -90px;
}
.decorator3 {
  top: -90px;
  max-width: 200px;
}
.premios img {
  width: 15%;
}
nav {
  position: absolute;
  top: 53px;
  right: 100px;
  background: #dc6a06;
  border-radius: 2px;
  z-index: 100;
  padding: 5px 20px;
}
nav ul > li {
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
.forgot-password {
  color: white;
  font-style: italic;
  margin-top: -5px;
  margin-bottom: 15px;
  display: block;
}
.factura-icon svg {
  fill: white;
  width: 38px;
}
.img-preview {
  max-width: 300px;
}
.upload-caption {
  color: white;
}
.image-uploader-container {
  margin: 20px 0;
}
.image-uploader-container >>> input[type="file"] {
  color: white !important;
}
</style>
