<template>
  <div class="SlotMachine">
    <div class="SlotMachine-reels">
      <SlotReel ref="reel1" v-on:stopped="reelStopped"></SlotReel>
      <SlotReel ref="reel2" v-on:stopped="reelStopped"></SlotReel>
      <SlotReel ref="reel3" v-on:stopped="reelStopped"></SlotReel>
    </div>
  </div>
</template>

<script>
import SlotReel from "@/components/SlotReel.vue";
export default {
  data() {
    return {
      win: 0,
      resultData: false,
      canlock: true,
      waslocked: false,
      audio: {
        win: new Audio(
          "https://freesound.org/data/previews/387/387232_1474204-lq.mp3"
        ),
        insertCoin: new Audio(
          "https://freesound.org/data/previews/276/276091_5123851-lq.mp3"
        ),
        bigwin: new Audio(
          "https://freesound.org/data/previews/270/270319_5123851-lq.mp3"
        ),
      },
    };
  },
  mounted: function mounted() {
    // window.addEventListener('keydown', this.keydown);
  },
  components: {
    SlotReel,
  },
  computed: {},
  methods: {
    keydown: function keydown(e) {
      console.log(e.which);
      var key = {
        one: 49,
        two: 50,
        three: 51,
        space: 32,
      };
      if (e.which === key.one) {
        this.$refs.reel1.lock();
        e.preventDefault();
      } else if (e.which === key.two) {
        this.$refs.reel2.lock();
        e.preventDefault();
      } else if (e.which === key.three) {
        this.$refs.reel3.lock();
        e.preventDefault();
      } else if (e.which === key.space) {
        this.spin();
        e.preventDefault();
      }
    },
    jugar: function jugar(lost) {
      if (lost) {
        this.spinLost();
      } else {
        this.spinWin();
      }
    },
    spin: function spin() {
      this.resultData = [];
      // this.credits = this.credits - 0.5;
      this.$refs.reel1.run();
      this.$refs.reel2.run();
      this.$refs.reel3.run();
    },
    spinLost: function spinLost() {
      this.resultData = [];
      // this.credits = this.credits - 0.5;
      this.$refs.reel1.runLost(15);
      this.$refs.reel2.runLost(10);
      this.$refs.reel3.runLost(13);
    },
    spinWin: function spinWin() {
      console.log("spin win");
      this.resultData = [];
      this.$refs.reel1.runLost(15);
      this.$refs.reel2.runLost(15);
      this.$refs.reel3.runLost(15);
    },
    insertCoin: function insertCoin() {
      this.audio.insertCoin.currentTime = 0;
      this.audio.insertCoin.play();
      //   this.credits += .5;
      //   this.spend += .5;
    },
    // takeWin: function takeWin() {
    //   if (this.win > 0) {
    //     this.credits += this.win;
    //     this.win = 0;
    //   }
    // },
    reelStopped: function reelStopped(resultData, wasLocked) {
      if (wasLocked) this.waslocked = wasLocked;

      this.resultData.push(resultData);
      if (this.resultData.length === 3) {
        this.checkWin(this.resultData);
        if (this.waslocked) {
          this.waslocked = false;
          this.canlock = false;
        } else {
          this.canlock = true;
        }
      }
    },

    checkWin: function checkWin() {
      if (this.resultData.length === 3) {
        // ;-)
        var v1 = this.resultData[0];
        var v2 = this.resultData[1];
        var v3 = this.resultData[2];
        if (v1.name === v2.name && v2.name === v3.name) {
          this.audio.win.play();
          this.win += v1.value;
          this.waslocked = true; // prevent lock after an unlocked win
          this.$emit("ganoPremio");
        } else {
          // Lose : (
          this.$emit("perdioPremio");
        }
      }
      this.resultData = false;
    },
  },
};
</script>

<style scoped>
.SlotMachine {
  border-radius: 5px;
}
.SlotMachine-reels {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.SlotMachine-shadow {
  border-radius: 4px 4px 0 0;
  pointer-events: none;
  z-index: 99;
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-box-shadow: inset 0 25px 30px -5px rgba(0, 0, 0, 0.1),
    inset 0 5px 10px -2px rgba(0, 0, 0, 0.2),
    inset 0 -25px 30px -5px rgba(0, 0, 0, 0.1),
    inset 0 -5px 10px -2px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 25px 30px -5px rgba(0, 0, 0, 0.1),
    inset 0 5px 10px -2px rgba(0, 0, 0, 0.2),
    inset 0 -25px 30px -5px rgba(0, 0, 0, 0.1),
    inset 0 -5px 10px -2px rgba(0, 0, 0, 0.2);
}
.SlotMachine-payline {
  position: absolute;
  top: 104.994px;
  height: 1px;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
}

.SlotMachine-stats {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: #000;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 10px 0;
}
.SlotMachine-coin {
  width: 6px;
  height: 38px;
  background: rgb(20, 20, 20);
  border: 3px solid rgb(40, 40, 40);
  border-radius: 6px;
  margin: 12px 10px 0 10px;
}
.SlotMachine-coin:hover {
  background: rgb(140, 140, 140);
}
.SlotMachine-stat {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-right: 10px;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.SlotMachine-statTitle {
  font-size: 12px;
  color: rgb(60, 60, 60);
}
.SlotMachine-statValue {
  padding: 5px 10px;
  background: rgba(255, 0, 0, 0.15);
  border-radius: 2px;
  border: 1px solid rgb(0, 0, 0);
  font-size: 25px;
  text-align: right;
  color: rgba(255, 0, 0, 0.8);
  text-shadow: 0 0 4px rgba(255, 0, 0, 0.5);
}
.SlotMachine-statSub {
  font-size: 9px;
  color: rgb(50, 50, 50);
  text-align: right;
}

.SlotMachine-actions {
  padding: 20px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;

  border-radius: 0 0 4px 4px;
}
.SlotMachine-button {
  font-size: 17px;
  font-weight: bold;
  padding: 14px 12px;
  margin-left: 20px;
  border-radius: 5px;
  -webkit-box-shadow: 0 1px 2px 2px rgb(0, 0, 0);
  box-shadow: 0 1px 2px 2px rgb(0, 0, 0);
}
.SlotMachine-button:focus {
  outline: none;
}
.SlotMachine-button:active {
  -webkit-box-shadow: 0 0 2px 1px rgb(0, 0, 0);
  box-shadow: 0 0 2px 1px rgb(0, 0, 0);
}

.SlotMachine-button.is-spin {
  background: rgba(0, 255, 0, 0.4);
  border: 1px solid rgba(0, 255, 0, 0.4);
}
.SlotMachine-button.is-spin:hover {
  background: rgba(0, 255, 0, 0.43);
}
.SlotMachine-button.is-spin:active {
  background: rgba(0, 255, 0, 0.46);
}

.SlotMachine-button.is-win {
  background: rgba(255, 0, 0, 0.4);
  border: 1px solid rgba(255, 0, 0, 0.5);
}
.SlotMachine-button.is-win.has-win {
  background: rgba(255, 0, 0, 0.99);
}
.SlotMachine-button.is-win:hover {
  background: rgba(255, 0, 0, 0.65);
}
.SlotMachine-button.is-win:active {
  background: rgba(255, 0, 0, 0.7);
}
</style>
