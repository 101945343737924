<template>
  <div class="home">
    <section id="first" class="pt-4 relative">
      <div class="logo ml-10">
        <router-link to="/"
          ><img class="logo-image" src="../assets/logo-tablita.svg"
        /></router-link>
      </div>
      <nav class="md:block hidden">
        <ul>
          <li>
            <router-link to="/ingreso-factura">Ingresa tu factura</router-link>
          </li>
          <li>
            <router-link to="/jugar">Juega y gana</router-link>
          </li>
          <li><router-link to="/">¿Cómo participo?</router-link></li>
          <li><router-link to="/">Premios</router-link></li>
        </ul>
      </nav>

      <div class="inner-container px-4 pt-4 pb-2 relative z-50">
        <div class="grid md:grid-cols-2 gap-12 md:px-20 px-4">
          <div class="logo">
            <img src="../assets/25_aniversario_tablita.png" />
          </div>
          <div>
            <img class="mx-auto" src="../assets/celebremos-sabor.png" />
            <h2 class="mt-4 uppercase text-white text-3xl font-bold">
              Inicia sesión y participa
            </h2>
            <router-link class="text-white underline roboto" to="/registro"
              >No tienes cuenta, ir a selección de registro.</router-link
            >
            <input
              class="block w-10/12 my-4 mx-auto"
              type="text"
              name="usuario"
              v-model="email"
              placeholder="Email:"
            />
            <div class="text-white italic" v-if="checkWhiteSpace">
              El mail no puede tener espacios en blanco
            </div>
            <input
              class="block w-10/12 my-4 mx-auto"
              type="password"
              name="password"
              v-model="password"
              placeholder="Contraseña:"
            />
            <a class="forgot-password roboto" @click="forgotPassword"
              >Olvidé mi contraseña</a
            >
            <a @click="login" class="mr-6"
              ><img class="mx-auto" src="../assets/entrar-btn.png"
            /></a>
          </div>
        </div>
        <div class="mt-12 text-center text-white">
          <a href="legales.html" target="_blank">
            *Promoción válida a nivel nacional para mayores de 12 años
            residentes en Ecuador, aplican términos y condiciones en la página
            web.</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import firebase from "firebase";
export default {
  name: "InicioSesion",
  components: {
    // HelloWorld
  },
  data() {
    return {
      email: "",
      password: "",
      user_id: "",
    };
  },
  computed: {
    checkWhiteSpace() {
      if (/\s/.test(this.email)) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    login() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then((userCredential) => {
          this.user_id = userCredential.user.uid;
          this.$store.state.user_id = this.user_id;
          this.$store.state.email = this.email;
          this.$store.state.autenticado = true;
          var redirect_to = this.$store.state.redirect;
          this.$router.push(redirect_to);
        })
        .catch((error) => {
          alert(error.message);
        });
    },
    forgotPassword() {
      this.$router.push("/forgotpassword");
    },
    gotoIngresa() {
      this.$router.push("/ingreso-codigo");
    },
  },
};
</script>
<style scoped>
#first {
  background: url("../assets/fondo-landing.jpg");
  overflow: hidden;
  min-height: 100vh;
}
input {
  border-radius: 15px;
  padding: 10px 15px;
}
input::placeholder {
  color: #430077;
  font-family: "Roboto", sans-serif;
}
.home {
}
.logo-image {
  max-width: 180px;
}
.inner-container {
  max-width: 1240px;
  margin: 0 auto;
}
.decorator1 {
  max-width: 200px;
}
.decorator2 {
  bottom: -90px;
}
.decorator3 {
  top: -90px;
  max-width: 200px;
}
.premios img {
  width: 15%;
}
nav {
  position: absolute;
  top: 53px;
  right: 100px;
  background: #dc6a06;
  border-radius: 2px;
  z-index: 100;
  padding: 5px 20px;
}
nav ul > li {
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
.forgot-password {
  color: white;
  font-style: italic;
  margin-top: -5px;
  margin-bottom: 15px;
  display: block;
}
</style>
