<template>
  <div
    class="Reel"
    v-bind:class="{ 'is-locked': locked }"
    v-on:mousedown="lock()"
  >
    <div class="Reel-inner">
      <img class="Reel-image" :src="reelTileData[tile1Index].image" />
      <img class="Reel-image" :src="reelTileData[tile2Index].image" />
      <img class="Reel-image" :src="reelTileData[tile3Index].image" />
      <img class="Reel-image" :src="reelTileData[tile4Index].image" />
      <img class="Reel-image" :src="reelTileData[tile5Index].image" />
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "canlock"],
  name: "SlotReel",
  components: {
    // HelloWorld
  },
  data() {
    return {
      momentum: null,
      audio: {
        spin: new Audio(
          "https://freesound.org/data/previews/120/120373_824230-lq.mp3"
        ),
        spinEnd: new Audio(
          "https://freesound.org/data/previews/145/145441_2615119-lq.mp3"
        ),
        lock: new Audio(
          "https://freesound.org/data/previews/56/56246_91374-lq.mp3"
        ),
      },
      reelTileCount: 15,
      reelTileData: null,
      reelSourceData: [
        {
          name: "Lemon",
          value: 2,
          image: "reel/filete.png",
        },
        {
          name: "Melon",
          value: 4,
          image: "reel/hamburguesa.png",
        },
        {
          name: "Grapes",
          value: 10,
          image: "reel/papas.png",
        },
        {
          name: "Cherry",
          value: 16,
          image: "reel/ensalada.png",
        },
        {
          name: "Bar",
          value: 32,
          image: "reel/cola.png",
        },
      ],
      reelIndex: 2,
      tile1Index: 0,
      tile2Index: 1,
      tile3Index: 2,
      tile4Index: 3,
      tile5Index: 4,
      locked: false,
    };
  },
  methods: {
    run: function run() {
      console.log("run");
      if (!this.locked) {
        var min = 8;
        var max = 28;
        var momentum = Math.floor(Math.random() * (max - min + 1) + min);
        this.momentum = momentum;
        this.audio.spin.play();
        this.animate();
      } else {
        this.locked = false;
        this.$emit("stopped", this.reelTileData[this.reelIndex], true);
      }
    },

    runLost: function runLost(momentum) {
      console.log("run Lost");
      if (!this.locked) {
        //var min = 8;
        //var max = 8;
        //var momentum = Math.floor(Math.random() * (max - min + 1) + min);
        this.momentum = momentum;
        this.audio.spin.play();
        this.animate();
      } else {
        this.locked = false;
        this.$emit("stopped", this.reelTileData[this.reelIndex], true);
      }
    },
    animate: function animate() {
      this.$el.classList.add("move");
    },
    animateEnd: function animateEnd() {
      this.$el.classList.remove("move");
      this.reIndex();
      this.momentum = this.momentum - 1;
      if (this.momentum > 0) {
        setTimeout(this.animate, 10);
      } else {
        this.$emit("stopped", this.reelTileData[this.reelIndex]);
        this.audio.spinEnd.play();
        this.audio.spin.pause();
        this.audio.spin.currentTime = 0.3;
      }
    },
    reIndex: function reIndex() {
      var end = this.reelTileData.length - 1; //this.reelTileCount - 1
      var index = this.reelIndex === 0 ? end : this.reelIndex - 1;
      // const index = this.index === end ? 0 : this.index + 1
      this.reelIndex = index;
      this.tile1Index = index === 1 ? end : index === 0 ? end - 1 : index - 2;
      this.tile2Index = index === 0 ? end : index - 1;
      this.tile3Index = index;
      this.tile4Index = index === end ? 0 : index + 1;
      this.tile5Index = index === end - 1 ? 0 : index === end ? 1 : index + 2;
    },
    lock: function lock() {
      if (this.canlock) {
        this.locked = !this.locked;
        this.audio.lock.play();
      }
    },
  },
  beforeMount() {
    // Build up the reelTileData array with random tiles
    var frs = [];
    // var count = this.reelTileCount;
    // console.log(count);
    this.audio.spin.volume = 0.3;
    this.audio.spinEnd.volume = 0.8;
    this.audio.lock.volume = 0.2;
    this.audio.spin.currentTime = 0.3;
    // Method 1, random until count is reached
    // while (count > 0) {
    //   const fruitIndex = Math.floor(Math.random() * this.reelSourceData.length)
    //   const fruitObject = this.reelSourceData[fruitIndex]
    //   frs.push(fruitObject)
    //   count--
    // }

    // Method 2, sort on value, use index to determine entry count, shuffle
    var reelSourceData = this.reelSourceData.slice(0);
    reelSourceData.sort(function (a, b) {
      return b.value - a.value;
    });
    reelSourceData.forEach(function (sd, i) {
      var times = i + 1 + i; // 0+1+0=1, 3+2+3=8
      while (times > 0) {
        frs.push(sd);
        times--;
      }
    });

    // function shuffle(array) {
    //   var currentIndex = array.length,
    //     temporaryValue,
    //     randomIndex;

    //   // While there remain elements to shuffle...
    //   while (0 !== currentIndex) {
    //     // Pick a remaining element...
    //     randomIndex = Math.floor(Math.random() * currentIndex);
    //     currentIndex -= 1;

    //     // And swap it with the current element.
    //     temporaryValue = array[currentIndex];
    //     array[currentIndex] = array[randomIndex];
    //     array[randomIndex] = temporaryValue;
    //   }

    //   return array;
    // }

    //this.reelTileData = shuffle(frs);
    this.reelTileData = frs;
  },
  mounted: function mounted() {
    this.$el.addEventListener("transitionend", this.animateEnd);
  },
};
</script>

<style scoped>
.Reel {
  width: 90px;
  height: 68px;
  overflow: hidden;
  margin-right: 23px;
}
.Reel.is-locked {
  /*   opacity: 0.8; */
  background: rgba(0, 0, 0, 0.1);
}
.Reel:not(:last-child) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.Reel-inner {
  position: relative;
  top: -189.97px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.Reel.move .Reel-inner {
  -webkit-transition: margin-top 0.04s linear;
  transition: margin-top 0.04s linear;
  margin-top: 90px;
}
.Reel-image {
  width: 90px;
  width: 90px;
}
@media only screen and (max-width: 767px) {
  .Reel {
    width: 48px;
  }
}
</style>
