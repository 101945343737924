<template>
  <div class="home">
    <section id="first" class="pt-4 relative">
      <div class="logo ml-10">
        <router-link to="/"
          ><img class="logo-image" src="../assets/logo-tablita.svg"
        /></router-link>
      </div>
      <nav class="md:block hidden">
        <ul>
          <li>
            <router-link to="/ingreso-factura">Ingresa tu factura</router-link>
          </li>
          <li>
            <router-link to="/jugar">Juega y gana</router-link>
          </li>
          <li><router-link to="/">¿Cómo participo?</router-link></li>
          <li><router-link to="/">Premios</router-link></li>
        </ul>
      </nav>

      <div class="inner-container px-4 pt-4 pb-2 relative z-50">
        <div
          v-if="puede_jugar"
          class="grid md:grid-cols-2 gap-12 md:px-20 px-4 juego-container"
        >
          <div class="slot-game-out">
            <div class="slot-game">
              <img class="slot-container" src="../assets/jackpot-01.png" />
              <SlotMachine
                @ganoPremio="ganoPremio"
                @perdioPremio="perdioPremio"
                ref="slotMachine"
                class="slot-master"
              />
            </div>
          </div>
          <div class="md:mt-10 mt-40">
            <a v-if="!play && !premio" @click="jugar" class="mr-6"
              ><img class="mx-auto juega-btn" src="../assets/juega-btn.png"
            /></a>
            <div
              class="text-white text-2xl md:mt-12 mt-4 mb-4"
              v-else-if="play && !premio"
            >
              SIGUE PARTICIPANDO, VUELVE MAÑANA PARA INTENTAR GANAR PREMIOS
              <router-link class="text-white block my-4 underline" to="/"
                >REGRESAR AL INICIO</router-link
              >
            </div>
            <div v-else-if="play && premio" class="mt-4 text-2xl text-white">
              <img class="mx-auto mb-4" :src="respuesta_premio.premio.image" />
              <p>GANASTE {{ respuesta_premio.premio.name }}</p>
              <p>ENVIAMOS UN CUPÓN A TU CORREO ELECTRÓNICO</p>
            </div>
            <div class="text-white mb-4">
              Recuerda que puedes participar una sola vez por día.
            </div>
          </div>
        </div>
        <div
          class="juego-container grid md:grid-cols-2 gap-12 md:px-20 px-4"
          v-else
        >
          <div class="slot-game">
            <img class="slot-container" src="../assets/jackpot-01.png" />
          </div>
          <div class="md:mt-20 mt-40">
            <div class="text-white text-2xl">
              INTENTA NUEVAMENTE EN {{ horasparaJugar }} HORAS PARA PODER
              PARTICIPAR
            </div>
            <router-link class="text-white block my-4 underline" to="/"
              >REGRESAR AL INICIO</router-link
            >
            <div class="text-white mb-4">
              Recuerda que puedes participar una sola vez por día.
            </div>
          </div>
        </div>
        <div class="mt-12 text-center text-white">
          <a href="legales.html" target="_blank">
            *Promoción válida a nivel nacional para mayores de 12 años
            residentes en Ecuador, aplican términos y condiciones en la página
            web.</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Vue from "vue";
import SlotMachine from "@/components/SlotMachine.vue";
import VueMoment from "vue-moment";
import axios from "axios";
import gql from "graphql-tag";

Vue.use(VueMoment);
export default {
  name: "jugarRuleta",
  components: {
    // HelloWorld
    SlotMachine,
  },
  data() {
    return {
      email: "",
      id_premio: "",
      user_id: "",
      play: false,
      premio: false,
      lost: false,
      puede_jugar: false,
      diferencia_horas: 0,
      respuesta: "",
      respuesta_premio: "",
    };
  },
  computed: {
    horasparaJugar() {
      var dif = 24 - this.diferencia_horas;
      if (dif < 0) {
        return 0;
      } else {
        return Math.round(dif);
      }
    },
  },
  methods: {
    getPuedeJugar() {
      // if (this.respuesta.)
      this.user_id = this.$store.state.user_id;
      this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: gql`
            query competitorEnable($competitor_external_id: String!) {
              competitorEnable(
                competitor_external_id: $competitor_external_id
              ) {
                success
                code
                message
                data
              }
            }
          `,
          variables: {
            competitor_external_id: this.user_id,
          },
        })
        .then((result) => {
          console.log(result.data);
          this.respuesta = result.data.competitorEnable.data;
          var fecha_actual = this.$moment(new Date());
          var end = this.$moment(
            this.respuesta.last_tried,
            "YYYY-MM-DD HH:mm:SS"
          );
          //var end_format = this.$moment(end).format("LL"); // another date
          var duration = this.$moment.duration(fecha_actual.diff(end));
          var hours = duration.asHours();
          this.diferencia_horas = hours;
          // console.log(hours);
          if (
            !this.respuesta.winner &&
            this.diferencia_horas > 0 &&
            this.respuesta.prizes
          ) {
            this.puede_jugar = true;
          } else if (
            !this.respuesta.winner &&
            this.diferencia_horas > 24 &&
            !this.respuesta.prizes
          ) {
            this.puede_jugar = true;
            this.lost = true;
          }
        });
    },
    ganoPremio() {
      console.log("gano premio");
      this.play = true;
      this.premio = true;
      this.enviarResultado();
    },
    perdioPremio() {
      console.log("perdio premio");
      this.play = true;
      this.enviarResultado();
    },
    jugar() {
      if (!this.play) this.$refs.slotMachine.jugar(this.lost);
    },
    enviarResultado() {
      this.$apollo
        .query({
          fetchPolicy: "no-cache",
          query: gql`
            query competitorTriedFront(
              $competitor_external_id: String!
              $tried_result: Boolean!
            ) {
              competitorTriedFront(
                competitor_external_id: $competitor_external_id
                tried_result: $tried_result
              ) {
                success
                code
                message
                data
              }
            }
          `,
          variables: {
            competitor_external_id: this.user_id,
            tried_result: this.premio,
          },
        })
        .then((result) => {
          console.log(result);
          this.respuesta_premio = result.data.competitorTriedFront.data;
          if (this.premio) {
            this.sendCoupon();
          }
        });
    },
    sendCoupon() {
      console.log("sending cupon");
      axios
        .post("https://www.latablitadeltartaro.com/cupones/API/new_cupon.php", {
          Op: "lttBsc",
          Codigo_promocion: parseInt(this.respuesta_premio.premio.desc),
          Correo: this.email,
        })
        .then((response) => {
          console.log(response);
        });
    },
  },

  mounted() {
    this.email = this.$store.state.email;
    this.getPuedeJugar();
  },
};
</script>
<style scoped>
#first {
  background: url("../assets/fondo-landing.jpg");
  overflow: hidden;
  min-height: 100vh;
}
input {
  border-radius: 15px;
  padding: 10px 15px;
}
input::placeholder {
  color: #430077;
}
.home {
}
.logo-image {
  max-width: 180px;
}
.inner-container {
  max-width: 1240px;
  margin: 0 auto;
}
.juego-container {
  min-height: 400px;
}
.slot-game {
  position: relative;
}
.slot-container {
  position: absolute;
  z-index: 10;
}
.slot-master {
  position: absolute;
  z-index: 20;
  top: 142px;
  left: 57px;
}
nav {
  position: absolute;
  top: 53px;
  right: 100px;
  background: #dc6a06;
  border-radius: 2px;
  z-index: 100;
  padding: 5px 20px;
}
nav ul > li {
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
.juega-btn {
  margin-top: 60px;
  max-width: 250px;
}
@media only screen and (max-width: 767px) {
  .slot-container {
    width: 300px;
    margin: 0 auto;
  }
  .slot-game {
    left: 5%;
  }
  .slot-master {
    top: 86px;
    left: 30px;
  }
  .juega-btn {
    margin-top: 0px;
    width: 70%;
  }
}
</style>
