<template>
  <div class="home">
    <section id="first" class="pt-4 relative">
      <div class="logo ml-10">
        <router-link to="/"
          ><img class="logo-image" src="../assets/logo-tablita.svg"
        /></router-link>
      </div>
      <nav class="md:block hidden">
        <ul>
          <li>
            <router-link to="/ingreso-factura">Ingresa tu factura</router-link>
          </li>
          <li>
            <router-link to="/jugar">Juega y gana</router-link>
          </li>
          <li><router-link to="/">¿Cómo participo?</router-link></li>
          <li><router-link to="/">Premios</router-link></li>
        </ul>
      </nav>

      <div class="inner-container px-4 pt-0 pb-2 relative z-50">
        <div class="grid md:grid-cols-2 gap-12 md:px-20 px-4 items-center">
          <div class="logo">
            <img src="../assets/25_aniversario_tablita.png" />
          </div>
          <div>
            <h2 class="text-white uppercase text-3xl font-bold">
              Completa tus datos
            </h2>
            <router-link to="/inicio-sesion" class="text-white underline"
              >¿Ya tienes una cuenta? Ir a la página de sesión.</router-link
            >
            <input
              class="block md:w-10/12 w-11/12 my-2 mx-auto"
              type="text"
              name="usuario"
              v-model="nombre"
              placeholder="Nombre:"
            />
            <input
              class="block md:w-10/12 w-11/12 my-2 mx-auto"
              type="text"
              name="apellido"
              v-model="apellido"
              placeholder="Apellido:"
            />
            <input
              class="block md:w-10/12 w-11/12 my-2 mx-auto"
              type="email"
              name="email"
              v-model="email"
              placeholder="Email:"
            />
            <input
              class="block md:w-10/12 w-11/12 my-2 mx-auto"
              type="text"
              name="telefono"
              v-model="telefono"
              placeholder="Teléfono:"
            />
            <!-- <date-dropdown
              min="1930"
              max="2020"
              default="1995-01-10"
              v-model="fecha_nacimiento"
              months-names="Enero,Febrero,Marzo,Abril,Mayo,Junio,Julio,Agosto,Septiembre,Octubre,Noviembre,Diciembre"
            /> -->
            <!-- <v-select
              class="ciudad-selector"
              v-model="ciudad"
              placeholder="Ciudad"
              :from="ciudades"
            ></v-select> -->
            <!-- <input
              class="block md:w-10/12 w-11/12 my-2 mx-auto"
              type="text"
              name="cedula"
              v-model="cedula"
              placeholder="Cédula:"
            /> -->
            <input
              class="block md:w-10/12 w-11/12 my-2 mx-auto"
              type="password"
              name="password"
              v-model="password"
              placeholder="Contraseña:"
            />
            <input type="checkbox" name="terminos" />
            <label class="text-white" for="terminos"
              >He leído y acepto la Política de privacidad.</label
            >
            <p v-if="error_formulario" class="text-white">
              Faltan datos de tu formulario por favor llènalo con todos tus
              datos
            </p>
            <p v-if="error_envio" class="text-white">
              {{ mensaje_error }}
            </p>
            <div v-if="email_existe" class="alert-message">
              Este mail ya se encuentra registrando
              <router-link to="/forgotpassword"
                >Olvidaste tu contraseña</router-link
              >
            </div>
            <div v-if="!registrando">
              <a @click="register" class="mt-4 block"
                ><img class="mx-auto" src="../assets/a-jugar-btn.png"
              /></a>
            </div>
            <div v-else>
              <img class="mx-auto" src="../assets/procesando_btn.png" />
            </div>
          </div>
        </div>
        <div class="mt-12 text-center text-white">
          <a href="legales.html" target="_blank">
            *Promoción válida a nivel nacional para mayores de 12 años
            residentes en Ecuador, aplican términos y condiciones en la página
            web.</a
          >
        </div>
      </div>
      <!-- <img
        class="absolute decorator4 md:block hidden"
        src="../assets/decorator3.png"
      /> -->
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import firebase from "firebase";
import gql from "graphql-tag";
// import { vSelect } from "@desislavsd/vue-select";
// import "@desislavsd/vue-select/dist/vue-select.css";
// import DateDropdown from "vue-date-dropdown";
import VueMoment from "vue-moment";
import Vuelidate from "vuelidate";
import {
  required,
  maxLength,
  minLength,
  integer,
  email,
} from "vuelidate/lib/validators";
Vue.use(VueMoment);
Vue.use(Vuelidate);
export default {
  name: "Registro",
  components: {
    // HelloWorld
    // vSelect,
    // DateDropdown,
  },
  data() {
    return {
      nombre: "",
      apellido: "",
      telefono: "",
      ciudad: "",
      fecha_nacimiento: "",
      email: "",
      cedula: "",
      password: "",
      user_id: "",
      error_formulario: false,
      error_envio: false,
      registrando: false,
      mensaje_error: "",
      email_existe: false,
      ciudades: [
        "ARAJUNO",
        "BIBLIAN",
        "BOLIVAR",
        "CALUMA",
        "CARLOS JULIO AROSEMENA TOLA",
        "CENTINELA DEL CONDOR",
        "CEVALLOS",
        "CHILLANES",
        "CHIMBO",
        "CHINCHIPE",
        "COLTA",
        "DELEG",
        "ECHEANDIA",
        "EL EMPALME",
        " EL PAN",
        "EL PANGUI",
        "ESMERALDAS",
        "ESPEJO",
        "ESPINDOLA",
        "FLAVIO ALFARO",
        "GONZALO PIZARRO",
        "GONZANAMA",
        "GUACHAPALA",
        "GUALAQUIZA",
        "GUARANDA",
        "GUAYAQUIL",
        "HUAMBOYA",
        "IBARRA",
        "ISABELA",
        "JAMA",
        "JARAMIJO",
        "JIPIJAPA",
        "LA JOYA DE LOS SACHAS",
        "LA LIBERTAD",
        "LA TRONCAL",
        "LAGO AGRIO",
        "LAS NAVES",
        "LOJA",
        "LORETO",
        "MANTA",
        "MEJIA",
        "MILAGRO",
        "MIRA",
        "MOCACHE",
        "MOCHA",
        "MONTALVO",
        "MONTECRISTI",
        "NABON",
        "NANGARITZA",
        "OÑA",
        "PATATE",
        "PEDERNALES",
        "PEDRO MONCAYO",
        "PEDRO VICENTE MALDONADO",
        "PENIPE",
        "PICHINCHA",
        "PINDAL",
        "PLAYAS",
        "PUCARA",
        "PUJILI",
        "PUTUMAYO",
        "PUYANGO",
        "QUERO",
        "SALCEDO",
        "SAN CRISTOBAL",
        "SAN FERNANDO",
        "SAN MIGUEL DE LOS BANCOS",
        "SAN PEDRO DE HUACA",
        "SANTA CLARA",
        "SANTA CRUZ",
        "SANTA ELENA",
        "SANTA ISABEL",
        "SANTA LUCIA",
        "SEVILLA DE ORO",
        "SIGSIG",
        "SUCRE",
        "SUCUMBIOS",
        "TOSAGUA",
        "TULCAN",
        "YACUAMBI",
        "YANTZAZA (YANZATZA)",
        "24 DE MAYO",
        "AGUARICO",
        "ALAUSI",
        "ALFREDO BAQUERIZO MORENO (JUJAN)",
        "AMBATO",
        "ANTONIO ANTE",
        "ARCHIDONA",
        "ARENILLAS",
        "ATACAMES",
        "ATAHUALPA",
        "AZOGUES",
        "BABA",
        "BABAHOYO",
        "BALAO",
        "BALSAS",
        "BALZAR",
        "BAÑOS DE AGUA SANTA",
        "BUENA FE",
        "CALVAS",
        "CAMILO PONCE ENRIQUEZ",
        "CAÑAR",
        "CANTON TIWINTZA",
        "CASCALES",
        "CATAMAYO",
        "CAYAMBE",
        "CELICA",
        "CHAGUARPAMBA",
        "CHAMBO",
        "CHILLA",
        "CHONE",
        "CHORDELEG",
        "CHUNCHI",
        "COLIMES",
        "CORONEL MARCELINO MARIDUEÑA",
        "COTACACHI",
        "CUENCA",
        "CUMANDA",
        "CUYABENO",
        "DAULE",
        "DURAN",
        "EL CARMEN",
        "EL CHACO",
        "EL GUABO",
        "EL TAMBO ",
        "EL TRIUNFO",
        "ELOY ALFARO",
        "GENERAL ANTONIO ELIZALDE",
        "GIRON",
        "GUALACEO",
        "GUAMOTE",
        "GUANO",
        "HUAQUILLAS",
        "ISIDRO AYORA",
        "JUNIN",
        "LA CONCORDIA",
        "LA MANA",
        "LAS LAJAS",
        "LATACUNGA",
        "LIMON INDANZA",
        "LOGROÑO",
        "LOMAS DE SARGENTILLO",
        "MACARA",
        "MACHALA",
        "MARCABELI",
        "MERA",
        "MONTUFAR",
        "MORONA",
        "MUISNE",
        "NARANJAL",
        "NARANJITO",
        "NOBOL",
        "OLMEDO",
        "ORELLANA",
        "OTAVALO",
        "PABLO SEXTO",
        "PAJAN",
        "PALANDA",
        "PALENQUE",
        "PALESTINA",
        "PALLATANGA",
        "PALORA",
        "PALTAS",
        "PANGUA",
        "PAQUISHA",
        "PASAJE",
        "PASTAZA",
        "PAUTE",
        "PEDRO CARBO",
        "PIMAMPIRO",
        "PIÑS",
        "PORTOVELO",
        "PORTOVIEJO",
        "PUEBLOVIEJO",
        "PUERTO LOPEZ",
        "PUERTO QUITO",
        "QUEVEDO",
        "QUIJOS",
        "QUILANGA",
        "QUININDE",
        "QUINSALOMA",
        "QUITO",
        "RIOBAMBA",
        "RIOVERDE",
        "ROCAFUERTE",
        "RUMIÑAHUI",
        "SALINAS",
        "SALITRE (URBINA JADO)",
        "SAMBORONDON",
        "SAN JACINTO DE YAGUACHI",
        "SAN JUAN BOSCO",
        "SAN LORENZO",
        "SAN MIGUEL",
        "SAN MIGUEL DE URCUQUI",
        "SAN PEDRO DE PELILEO",
        "SAN VICENTE",
        "SANTA ANA",
        "SANTA ROSA",
        "SANTIAGO",
        "SANTIAGO DE PILLARO",
        "SANTO DOMINGO",
        "SAQUISILI",
        "SARAGURO",
        "SHUSHUFINDI",
        "SIGCHOS",
        "SIMON BOLIVAR",
        "SOZORANGA",
        "SUCUA",
        "SUSCAL",
        "TAISHA",
        "TENA",
        "TISALEO",
        "URDANETA",
        "VALENCIA",
        "VENTANAS",
        "VINCES",
        "ZAMORA",
        "ZAPOTILLO",
        "ZARUMA",
      ],
    };
  },
  validations: {
    nombre: {
      required,
    },
    apellido: {
      required,
    },
    password: {
      required,
    },
    email: {
      required,
      email,
    },
    // ciudad: {
    //   required,
    // },
    telefono: {
      required,
      integer,
      minLength: minLength(10),
      maxLength: maxLength(10),
    },
    // cedula: {
    //   required,
    //   integer,
    //   minLength: minLength(10),
    //   maxLength: maxLength(10),
    // },
    // fecha_nacimiento: {
    //   required,
    // },
  },
  methods: {
    register() {
      if (!this.registrando) {
        this.mensaje_error = "";
        this.error_envio = false;
        this.email_existe = false;
        console.log("registrando");
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.error_formulario = true;
        } else {
          this.error_formulario = false;
          this.registrando = true;
          firebase
            .auth()
            .createUserWithEmailAndPassword(this.email, this.password)
            .then((userCredential) => {
              this.user_id = userCredential.user.uid;
              console.log(userCredential.user);
              this.savetoBase();
            })
            .catch((error) => {
              // alert(error.message);
              console.log(error);
              this.error_envio = true;

              this.registrando = false;
              switch (error.message) {
                case "Password should be at least 6 characters":
                  this.mensaje_error =
                    "El password tiene que tener por lo menos 6 caracteres";
                  break;
                case "The email address is already in use by another account.":
                  this.mensaje_error = "";
                  this.email_existe = true;
                  break;
                default:
                  this.mensaje_error =
                    "Hubo un error al enviar tus datos por favor intenta más tarde";
              }
            });
        }
      }
    },
    savetoBase() {
      console.log("guardando en base");
      let input_usuario = {
        competitor_firstname: this.nombre,
        competitor_lastname: this.apellido,
        competitor_email: this.email,
        competitor_phone: this.telefono,
        competitor_identification: this.cedula,
        competitor_city: this.ciudad,
        competitor_dob: this.fecha_nacimiento,
        competitor_external_id: this.user_id,
      };
      this.$apollo
        .mutate({
          mutation: gql`
            mutation createCompetitor($input: _competitor!) {
              createCompetitor(input: $input) {
                success
              }
            }
          `,
          variables: {
            input: input_usuario,
          },
        })
        .then((result) => {
          console.log(result);
          if (result.data.createCompetitor.success) {
            var redirect_to = this.$store.state.redirect;
            this.$router.push(redirect_to);
          } else {
            this.error_envio = true;
            // this.$router.push('/');
            this.registrando = false;
          }
        });
    },
    gotoIngresa() {
      this.$router.push("/inicio-sesion");
    },
  },
};
</script>
<style scoped>
#first {
  background: url("../assets/fondo-landing.jpg");
  overflow: hidden;
  min-height: 100vh;
}
input {
  border-radius: 15px;
  padding: 10px 15px;
  font-family: "Roboto", sans-serif;
}
input::placeholder {
  color: #430077;
}
.home {
}
.logo-image {
  max-width: 180px;
}
.inner-container {
  max-width: 1240px;
  margin: 0 auto;
}
.premios img {
  width: 15%;
}
nav {
  position: absolute;
  top: 53px;
  right: 100px;
  background: #dc6a06;
  border-radius: 2px;
  z-index: 100;
  padding: 5px 20px;
}
nav ul > li {
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
.ciudad-selector {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 40px;
  margin-right: 45px;
  border-radius: 10px;
}
.date-dropdown-container {
  justify-content: center;
  margin-left: 5px;
}
.date-dropdown-container >>> .date-dropdown-select {
  border-radius: 10px;
}
.date-dropdown-container >>> .date-dropdown-select.day {
  width: 95px;
}
.date-dropdown-container >>> .date-dropdown-select.month {
  width: 170px;
}
.date-dropdown-container >>> .date-dropdown-select.year {
  width: 130px;
}
.alert-message {
  color: white;
  margin: 15px 0;
  font-style: italic;
}
.alert-message >>> a {
  text-decoration: underline;
}
@media only screen and (max-width: 767px) {
  .date-dropdown-container >>> .date-dropdown-select.day {
    width: 70px;
  }
  .date-dropdown-container >>> .date-dropdown-select.month {
    width: 115px;
  }
  .date-dropdown-container >>> .date-dropdown-select.year {
    width: 80px;
  }
  .ciudad-selector {
    margin-left: 15px;
    margin-right: 15px;
  }
}
</style>
